let weekdayFormatter = new Intl.DateTimeFormat('en-GB', { weekday: 'long' });
let timeFormatter = new Intl.DateTimeFormat('en-GB', { minute: 'numeric', hour: 'numeric'})

export function getTimeAndDay() {
  let date = new Date();
  return `- me at ${timeFormatter.format(date)} on a ${weekdayFormatter.format(date)}`;
}

export function insertTime() {
  let el = document.querySelector('.date-container');
  el.innerHTML = getTimeAndDay();
}

insertTime();
